@import '../../index.scss';

.gamecontainer_container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  background-image: url($image_pre + 'Game_Play_Background_Base2x.png');
  background-size: 100% 100%;
}
.gamecontainer_container_background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  background-image: url($image_pre + 'Game_Play_Background2x.png');
  background-size: 97.6% 98.2%;
  background-repeat: no-repeat;
}
.gamecontainer_contents {
  position: relative;
  z-index: 1;
  margin: 14vh 9vw;
  width: 76%;
  height: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gamecontainer_backbutton {
  @extend .hovercursor;
  width: 0px;
  height: 0px;
  background-image: url($image_pre +'Button_back_up.png');
  &:hover {
    background-image: url($image_pre +'Button_back_down.png');
  }
  background-size: 100% 100%;
  position: absolute;
  // left: 1vw;
  // top: 50%;
  animation-name: buttongrow;
  animation-duration: 0.5s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  transform: translate(calc(clamp(20px, 8vw, 48px) / 2), calc(clamp(22.5px, 9.1vw, 54px)) / 2);
}
$banner_width: clamp(350px, 95vmin, 1000px);
.gamecontainer_topbanner {
  position: absolute;
  width: $banner_width;
  height: calc($banner_width / 5.25);
  transform: translateY(calc(0vmin - ((95vmin / 5.25)*2)));
  animation-name: bannerdrop;
  animation-duration: 1s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  left: calc((100% - $banner_width) / 2);
  background-image: url($image_pre + 'Game_Play_Header2x.png');
  background-size: 100% 100%;
  bottom: 95%;
}

.gamecontainer_topbanner_contents {
  position: absolute;
  bottom: 5%;
  right: 5%;
  left: 5%;
  height: 59%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
$badge_dimms: clamp(40px, 9vmin, 100px);
$badge_margin_horizontal: clamp(5px, 1.5vmin, 15px);
.gamecontainer_topbanner_badge {
  margin: 0 clamp(5px, 1.5vmin, 15px);
  height: $badge_dimms;
  width: $badge_dimms;
  background-size: 100% 100%;
}
$headertext_dropshadow_dimm: clamp(1px, 0.5vmin, 3px);
.gamecontainer_topbanner_headertext {
  color: rgba(247,149,28,1);
  filter: drop-shadow($headertext_dropshadow_dimm $headertext_dropshadow_dimm 0px black);
  text-align: center;
  white-space: nowrap;
  font-size: clamp(20px, 5.5vmin, 45px);
}

// $progressbar_containerwidth: clamp(200px, 25vw, 350px);
// .gamecontainer_progressbar_container {
//   position: absolute;
//   top: 100%;
//   left: calc(50% - calc(1.1 * calc($progressbar_containerwidth / 2)));
// }
@media (max-height: 500px) {
  // $progressbar_containerwidth: clamp(100px, 12.5vw, 175px);
  .gamecontainer_topbanner {
    top: calc(calc($banner_width / 5.25) * -1);
  }
  // .gamecontainer_progressbar_container {
  //   left: calc(50% - calc(1.1 * calc($progressbar_containerwidth / 2)));
  // }
}
.gamecontainer_statcontainer {
  // width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: clamp(40px, 18vmin, 100px);
  position: absolute;
  bottom: 1vmin;
  left: 2vmin;
  right: 2vmin;
}
.gamecontainer_statcontents {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: clamp(60px, 12vmin, 100px);
  height: clamp(67.8px, 13.6vmin, 113px);
  background-image: url($image_pre + 'Game_Type_Header2x.png');
  background-size: 100% 100%;
}
.gamecontainer_statcontents_banner {
  position: absolute;
  bottom: 10%;
  width: 100%;
  height: 30%;
  background-image: url($image_pre + 'Game_Type_Stars_Base2x.png');
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gamecontainer_statcontents_stat {
  position: relative;
  bottom: 10%;
  color: white;
  filter: drop-shadow(2px 2px 0 black);
}
.gamecontainer_statcontents_banner p {
  color: white;
  filter: drop-shadow(1px 1px 0px black);
  font-size: clamp(15px, 3.7vmin, 25px);
}
.timeoutdiv {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, .3);
}

@keyframes bannerdrop {
  0% {
    transform: translateY(calc(0vmin - ((95vmin / 5.25)*2)));
  } 100% {
    transform: translateY(0px);
  }
}
@keyframes buttongrow {
  0% {
    width: 0px;
    height: 0px;
    right: 103%;
    top: 52%;
  } 100% {
    width: clamp(20px, 8vw, 48px);
    height: clamp(22.5px, 9.1vw, 54px);
    right: 101%;
    top: 50%;
  }
}


.gamecontainer_gametypebadgecontainer {
  position: absolute;
  bottom: clamp(67.8px, 13.6vmin, 113px);
  left: 20px;
  background-image: url($image_pre + 'Game_Type_Header2x.png');
  background-size: 100% 100%;
  width: clamp(60px, 12vmin, 100px);
  height: clamp(67.8px, 11.3vmin, 113px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}
.gamecontainer_gametypebadgecontainer_alt {
  @extend .gamecontainer_gametypebadgecontainer;
  bottom: 10px;
  left: 10px;
  margin-bottom: 0px;
}

.gamecontainer_gametypebadge {
  width: clamp(50px, 10vmin, 83px);
  height: clamp(56.5px, 13.6vmin, 94.4px);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}