.cardflip_container {
  position: absolute;
  perspective: 1000px;
}
.cardflip_container.active .cardflip_flipper {
  transform: rotateY(180deg);
}
.cardflip_container, .cardflip_front, .cardflip_back {
  border-radius: 15px;
}
.cardflip_flipper {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}
.cardflip_front, .cardflip_back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
.cardflip_front {
  z-index: 2;
  background-color: lightgreen;
  transform: rotateY(0deg);
  border-color: black;
  border-style: solid;
  border-width: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.cardflip_back {
  transform: rotateY(180deg);
  border-color: black;
  border-style: solid;
  border-width: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: rgba(49, 181, 255, 1);
}