@import '../../index.scss';

.subunitmenu_container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url($image_pre + 'Game_Selection_Background2x.png');
  background-size: 100% 100%;
}
.subunitmenu_innercontainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.4);
  display: flex;
  overflow: auto;
  padding-top: 80px;
}
.subunitmenu_contentcontainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100vw;
}
// top: 0.14;
// bottom: 0.12;




$lowercontentmargin: 20px;
$lowercontentwidth: clamp(400px, 95vw, 1300px);
.subunitmenu_lowercontent {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}
.subunitmenu_lowercontentcontainer {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: $lowercontentwidth;
  min-height: calc($lowercontentwidth * 0.54);
  background-size: 100% 100%;
  background-image: url($image_pre + 'Game_Selection_Display2x.png');
  margin: 0px 2.5vw;
}
.subunitmenu_innerlowercontentcontainer {
  position: relative;
  // height: 100%;
  padding-top: 14%;
  margin-right: calc($lowercontentwidth * 0.15);
  padding-bottom: 12%;
  margin-left: calc($lowercontentwidth * 0.1);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  overflow: auto;
}






.subunitmenu_innerlowercontentcontainer::-webkit-scrollbar {
  display: none;
}
.subunitmenu_lowercontainertile {
  position: relative;
  margin: 5px;
  height: clamp(100px, 20vmin, 150px);
  width: clamp(100px, 20vmin, 150px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  background-size: 100% 100%;
  background-image: url($image_pre + 'SubUnitMenu_TileImg.png');
}
.subunitmenu_tileimg {
  height: clamp(66px, 12.7vmin, 100px);
  width: clamp(66px, 12.7vmin, 100px);
  background-size: 100% 100%;
  position: absolute;
  top: clamp(20px, 5vmin, 35px);
}
.subunitmenu_tileheader {
  display: flex;
  padding: 4px;
  justify-content: center;
  width: clamp(100px, 20vmin, 150px);
  background-size: 100% 100%;
  background-image: url($image_pre + 'SubUnitMenu_TileHeaderImg.png');
}

.subunitmenu_tileheadertext {
  width : 95%;
  color: white;
  text-shadow: 1px 1px 0 black;
  word-wrap: break-word;
  text-align: center;
}