@import '../../../index.scss';

.roundbanner_banner {
  display: flex;
  align-items: center;
  background-image: url($image_pre + 'Modal_Message_Display2x.png');
  background-size: 100% 100%;
  width: clamp(368px, 73.6vmin, 736px);
  height: clamp(100px, 20vmin, 200px);
}
// w:h ratio = 3.68:1
.roundbanner_text {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: clamp(25px, 20vmin, 100px);
  color: rgba(255,255,255,1);
  filter: drop-shadow(4px 4px 0 rgba(150,150,150,1));
}