@import '../../../index.scss';

.readytostart_overlayscreen {
  position: fixed;
  z-index: 999999999999;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, .7);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  line-height: 1.3em;
}

.readytostart_box {
  position: relative;
  background-image: url($image_pre + 'Game_Play_Success_Display_Base2x.png');
  background-size: 100% 100%;
  width: clamp(90.9px, 54.6vmin, 455px);
  height: clamp(136.5px, 81.9vmin, 682.5px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  font-size: max(2.46vw, 13px);
}
.readytostart_contentcontainer {
  // background-color: rgba(255,0,0,0.5);
  position: absolute;
  top: 10%;
  left: 12%;
  bottom: 15%;
  right: 13%;
}

.readytostart_button {
  font-size: max(1.67vw, 9px);
  min-height: 40px;
  min-width: 100px;
  height: 6.12vw;
  width: 14.29vw;
  max-width: 280px;
  max-height: 100px;
  background-color: rgba(49, 181, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  color: white;
  font-weight: bold;
  margin: 20px;
  box-shadow: 2px 2px 2px black;
}