@import '../../index.scss';

.playagain_container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  background-image: url($image_pre + 'Game_Play_Background_Base2x.png');
  background-size: 100% 100%;
}

.playagain_container_background {
  position: absolute;
  top: 9.4%;
  bottom: 0;
  left: 8%;
  right: 0;
  z-index: 0;
  background-image: url($image_pre + 'Game_Play_Background_2_Full2x.png');
  background-size: 86.1% 89.2%;
  background-repeat: no-repeat;
}

.playagain_contents {
  position: relative;
  z-index: 1;
  margin: 14vh 9vw;
  width: 76%;
  height: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.playagain_backbutton {
  @extend .hovercursor;
  width: 0px;
  height: 0px;
  background-image: url($image_pre +'Button_back_up.png');
  &:hover {
    background-image: url($image_pre +'Button_back_down.png');
  }
  background-size: 100% 100%;
  position: absolute;
  left: 1vw;
  top: 50%;
  animation-name: buttongrow;
  animation-duration: 0.5s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  transform: translate(calc(clamp(20px, 8vw, 48px) / 2), calc(clamp(22.5px, 9.1vw, 54px)) / 2);
}
$banner_width: clamp(350px, 95vmin, 1000px);
.playagain_topbanner {
  position: absolute;
  width: $banner_width;
  height: calc($banner_width / 5.25);
  transform: translateY(calc(0vmin - ((95vmin / 5.25)*2)));
  animation-name: bannerdrop;
  animation-duration: 1s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  left: calc((100vw - $banner_width) / 2);
  background-image: url($image_pre + 'Game_Play_Header2x.png');
  background-size: 100% 100%;
  top: 0;
}
.playagain_topbanner_contents {
  position: absolute;
  bottom: 5%;
  right: 5%;
  left: 5%;
  height: 59%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.playagain_topbanner_badge {
  margin: 0 clamp(5px, 1.5vmin, 15px);
  height: clamp(40px, 9vmin, 100px);
  width: clamp(40px, 9vmin, 100px);
  background-size: 100% 100%;
}
.playagain_topbanner_headertext {
  color: rgba(247, 149, 28, 1);
  filter: drop-shadow(3px 3px 0px black);
  text-align: center;
  white-space: nowrap;
}
$progressbar_containerwidth: clamp(200px, 25vw, 350px);
.playagain_progressbar_container {
  position: absolute;
  bottom: 0.5vh;
  left: calc(50vw - calc(1.1 * calc($progressbar_containerwidth / 2)));
}
.playagain_statkeeper {
  background-image: url($image_pre + 'Game_Selection_Display2x.png');
  background-size: 100% 100%;
  height: clamp(300px, 60vmin, 1458px);
  width: clamp(200px, 90vmin, 2711px);
  position: relative;
}
.playagain_statkeeper_contents {
  position: absolute;
  top: 11%;
  bottom: 9.4%;
  left: 8%;
  right: 13.5%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-color: rgba(0, 0, 0, 0.4);
}
.playagain_statkeeper_column {
  // background-color: rgba(0,0,255,0.4);
  width: 42%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.playagain_statkeeper_column_left {
  @extend .playagain_statkeeper_column;
  justify-content: space-evenly;
}
.playagain_statbanner {
  width: clamp(90px, 26.8vmin, 432px);
  height: clamp(38.6px, 12vmin, 193px);
  background-image: url($image_pre + 'Game_Play_Success_Display_Stat_Back2x.png');
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.playagain_statnumber {
  -webkit-text-stroke: clamp(0.5px, 0.2vmin, 1.5px) white;
  filter: drop-shadow(2px 1px 0 black);
  font-size: clamp(1.1rem, 4vmin, 2.2rem);
  background:
    -webkit-linear-gradient(rgb(104, 200, 240) 45%,
      rgb(16, 114, 186) 55%,
    );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0px !important;
  letter-spacing: 3px;
  background-color: aqua;
}
.playagain_statname {
  font-size: clamp(0.85rem, 2.5vmin, 1.25rem);
  letter-spacing: clamp(0.08rem, 0.3vmin, 0.14rem);
  white-space: nowrap;
  margin: 0px !important;
  -webkit-text-stroke: 0.25px black;
  color: white;
}
.playagain_closebutton {
  background-image: url($image_pre + 'Game_Play_Success_Display_Close_Button_Up2x.png');
  background-size: 100% 100%;
  position: absolute;
  top: -2%;
  right: -7.5%;
  height: clamp(43.4px, 7vmin, 52px);
  width: clamp(41px, 6.4vmin, 49.2px);
}
.playagain_closebutton:hover {
  background-image: url($image_pre + 'Game_Play_Success_Display_Close_Button_Down2x.png');
  height: clamp(40px, 6.7vmin, 48px);
  width: clamp(41px, 6.83vmin, 49.2px);
  top: calc(-2% + 3px);
}
.playagain_levelcontainer {
  // position: relative;
  // right: 6%;
  background-image: url($image_pre + 'Game_Play_Success_Display_Header2x.png');
  background-size: 100% 100%;
  // margin-top: 15%;
  height: clamp(30px, 7vmin, 92px);
  width: clamp(108.5px, 25.7vmin, 334px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.playagain_leveltextcontainer {
  filter: drop-shadow(-1px -1px 0px rgba(196,180,52,1.0));
}
.playagain_leveltext {
  position: relative;
  left: 4px;
  color: white;
  font-size: clamp(18px, 4vmin, 40px);
  // -webkit-text-stroke: 6px rgba(0,0,0,0.1);
  // filter: drop-shadow(2px 2px 0px rgba(196,180,52,1.0));
  transform: skewX(-10deg);
  letter-spacing: 4px;
  text-shadow: 2px 0 rgba(0, 0, 0, 0.4), 0 3px rgba(0, 0, 0, 0.4);
}
.playagain_starcontainer {
  display: flex;
  width: clamp(75px, 27vmin, 300px);
  height: clamp(25px, 9vmin, 100px);
}
.playagain_fullstar {
  background-size: 100% 100%;
  flex: 1;
}
.playagain_starbase {
  display: flex;
  flex: 1;
  background-size: 100% 100%;
  height: 90%;
}
.playagain_starone {
  @extend .playagain_starbase;
  height: 85%;
  position: relative;
  top: 17%;
  left: 1%;
  background-image: url($image_pre + 'Game_Play_Success_Display_Star_Base_12x.png');
  .playagain_fullstar {
    background-image: url($image_pre + 'Game_Play_Success_Display_Star_12x.png');
  }
}
.playagain_startwo {
  @extend .playagain_starbase;
  position: relative;
  z-index: 1;
  background-image: url($image_pre + 'Game_Play_Success_Display_Star_Base_22x.png');
  .playagain_fullstar {
    background-image: url($image_pre + 'Game_Play_Success_Display_Star_22x.png');
  }
}
.playagain_starthree {
  @extend .playagain_starbase;
  height: 85%;
  position: relative;
  top: 17%;
  right: 1%;
  background-image: url($image_pre + 'Game_Play_Success_Display_Star_Base_32x.png');
  .playagain_fullstar {
    background-image: url($image_pre + 'Game_Play_Success_Display_Star_32x.png');
  }
}
.playagain_bluestreak {
  width: clamp(90px, 28vmin, 275px);
  height: clamp(38.6px, 9.14vmin, 87.1px);
  background-image: url($image_pre + 'Game_Play_Success_Display_Score_Back2x.png');
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.playagain_fullscorecontainer {
  position: relative;
  top: 7%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.playagain_scorecontainer {
  position: relative;
}
.playagain_yourscore {
  position: relative;
  top: 1vmin;
  font-size: clamp(0.85rem, 2.5vmin, 1.25rem);
  letter-spacing: clamp(0.08rem, 0.3vmin, 0.14rem);
  white-space: nowrap;
  line-height: 0px;
  margin: 0.4rem !important;
  -webkit-text-stroke: 0.5px white;
}
.playagain_previousbest {
  @extend .playagain_yourscore;
  font-size: clamp(0.7rem, 2vmin, 1.1rem);
  letter-spacing: 0.05rem;
}
.playagain_scorenumber {
  position: relative;
  -webkit-text-stroke: clamp(0.5px, 0.2vmin, 1.5px) white;
  filter: drop-shadow(2px 1px 0 black);
  font-size: clamp(1.1rem, 4vmin, 2.2rem);
  background:
    -webkit-linear-gradient(
      rgba(222, 121, 38, 1.0) 45%,
      rgba(165, 91, 37, 1.0) 55%,
    );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: clamp(1px, 0.7vmin, 20px) 0.4rem 0.4rem 0.4rem !important;
}
.playagain_greenarrow {
  position: absolute;
  right: -60%;
  height: clamp(67px, 18vmin, 201px);
  width: clamp(79px, 21.3vmin, 237px);
  background-image: url($image_pre + 'Game_Play_Success_Display_Flourish_12x.png');
  background-size: 100% 100%;
  transform: scaleX(-100%);
}
.playagain_highestscoreimg {
  position: absolute;
  top: -20%;
  left: -15%;
  height: clamp(40px, 9vmin, 120px);
  width: clamp(48.6px, 11.4vmin, 152.4px);
  background-image: url($image_pre + 'Game_Play_Success_Display_Flourish_22x.png');
  background-size: 100% 100%;
  transform: rotateZ(-40deg);
}
@media (max-width: 500px) {
  .playagain_highestscoreimg {
    top: 20%;
  }
}

.playagain_continuebutton {
  border-radius: 3px;
  background-image: url($image_pre + 'Game_Play_Success_Display_Continue_Button2x_WWord.png');
  background-size: 100% 100%;
  height: clamp(24px, 6vmin, 50px);
  width: clamp(86.4px, 21.6vmin, 180px);
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes bannerdrop {
  0% {
    transform: translateY(calc(0vmin - ((95vmin / 5.25)*2)));
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes buttongrow {
  0% {
    width: 0px;
    height: 0px;
    left: 3vw;
    top: 52vh;
  }
  100% {
    width: clamp(20px, 8vw, 48px);
    height: clamp(22.5px, 9.1vw, 54px);
    left: 1vw;
    top: 50vh;
  }
}

.playagain_gametypebadgecontainer {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-image: url($image_pre + 'Game_Type_Header2x.png');
  background-size: 100% 100%;
  width: 110px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.playagain_gametypebadge {
  width: 100px;
  height: 100px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}