.locationmenu_container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  // overflow: hidden;
  display: flex;
  flex-direction: column;
  background-image: url('./map/map.png');
  background-size: 100% 100%;
}
.locationmenu_townbutton {
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.locationmenu_townbutton:hover {
  filter: drop-shadow(0px 0px 5px white);
}