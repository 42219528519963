@import '../../index.scss';

.whackamole_keywordbox {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 5%;
  background-size: 100% 100%;
  margin-top: 15px;
  height: clamp(75px, 9.6vmin, 120px);
  width: clamp(262.5px, 33.6vmin, 420px);
  background-image: url($image_pre + 'Game_Type_Display2x.png');
}


.whackamole_mole {
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 95% 95%;
}

.whackamole_keyword {
  border-radius: 5px;
  font-size: 5vmin;
  padding: 1vmin 2vmin 0vmin 2vmin;
  line-height: 1.4em;
}