@import '../../index.scss';

.fullgamecontainer_container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(4, 174, 239, 1.0);
}

$progressbar_containerwidth: clamp(200px, 25vw, 350px);

.gamecontainer_progressbar_container {
  position: absolute;
  z-index: 3000000000000000;
  top: 90%;
  left: calc(50% - calc(1.1 * calc($progressbar_containerwidth / 2)));
}

@media (max-height: 500px) {
  $progressbar_containerwidth: clamp(100px, 12.5vw, 175px);
  .gamecontainer_progressbar_container {
    left: calc(50% - calc(1.1 * calc($progressbar_containerwidth / 2)));
  }
}