@import '../../../index.scss';

.progressbar_container {
  background-image: url($image_pre + 'Common_Progress_Base2x.png');
  background-size: 100% 100%;
  margin: 0px clamp(5px, 3vmin, 20px);
  position: relative;
}

.progressbar_contents {
  background-image: url($image_pre + 'Common_Progress_Top2x.png');
  background-size: 100% 100%;
  position: relative;
  bottom: 4px;
  right: 2px;
  display: flex;
  align-items: center;
}

.progressbar_purplebar {
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  animation-direction: normal;
  height: 40%;
  position: relative;
  background-color: rgba(120, 90, 166, 1.0);
  border-radius: 20px;
  overflow: hidden;
}
.progressbar_purplebar_timer {
  @extend .progressbar_purplebar;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-delay: 0s;
}

.progressbar_stripedbar {
  background-image: url($image_pre + 'Common_Progress_Alt_Bar2x.png');
  background-repeat: repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  p {
    position: relative;
    z-index: 10000;
    font-size: clamp(12px, 4vmin, 1.1em);
    letter-spacing: 0.07em;
    color: white;
    padding-right: 4px;
  }
}