@import '../../index.scss';

.unitmenu_container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background-image: url($image_pre + 'Category_Selection_BG_12x.png');
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
}
.unitmenu_bannercontainer {
  transform: translateX(-100vw);
  animation: slide-right 0.8s ease-out 0.3s 1 normal forwards;
  position: fixed;
  right: 7%;
  top: clamp(5px, 5vmin, 40px);
  z-index: 3;
  height: clamp(15px, 10vw, 150px);
  width: clamp(60px, 40vw, 600px);
  @media (max-width: 768px) {
    right: 2%;
  }
  @media (max-width: 410px) {
    width: clamp(60px, 38vw, 600px);
  }
}
.unitmenu_banner {
  width: 100%;
  height: 100%;
  background-image: url($image_pre + 'Modal_Message_Display2x.png');
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.unitmenu_banner h1 {
  position: relative;
  right: 8%;
  color: rgba(247,149,28,1);
  filter: drop-shadow(-3px 3px 0px black);
  font-size: clamp(15px, 5vmin, 60px);
  @media (max-width: 768px) {
    filter: drop-shadow(-2px 2px 0px black);
  }
  @media (max-width: 410px) {
    filter: drop-shadow(-1px 1px 0px black);
  }
}
.unitmenu_guidecharacter {
  position: absolute;
  bottom: 35%;
  right: 90%;
  @media (max-width: 768px) {
    bottom: 0;
  }
  height: clamp(30px, 10.5vmin, 110px);
  width: clamp(30px, 10.5vmin, 110px);
  background-image: url($image_pre + 'Guide_Character_Badge2x.png');
  background-size: 100% 100%;
}
.unitmenu_contents {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
  flex: 1;
  padding-top: 50px;
  @media (min-width: 1150px) {
    padding-top: 80px;
  }
  @media (max-width: 964px) {
    padding-top: 130px;
  }
  @media (max-width: 774px) {
    padding-top: 330px;
  }
  @media (max-width: 584px) {
    padding-top: 500px;
  }
  @media (max-width: 394px) {
    padding-top: 1700px;
  }
}
.unitmenu_smallscreencontainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}
.unitmenu_backwardunit {
  background-image: url($image_pre + 'Unit_Backward.png');
  height: 10vw;
  width: 10vw;
  background-size: 100% 100%;
}
.unitmenu_forwardunit {
  @extend .unitmenu_backwardunit;
  background-image: url($image_pre + 'Unit_Forward.png');
}
.unit_container {
  transform: translateY(-100vh);
  animation-name: slide-down;
  animation-duration: 0.75s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(.03, .78, .43, 1.39);
  animation-iteration-count: 1;
  animation-direction: normal;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 225px;
  width: 170px;
  margin: 10px;
}
@for $i from 1 through 11 {
  .unit_container:nth-child(#{$i}n) {
    animation-delay: #{$i * 0.2}s;
  }
}
.unit_background {
  position: absolute;
  z-index: 0;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url($image_pre + 'Catergory_Selection_Display2x.png');
  display: flex;
  justify-content: center;
  height: 225px;
  width: 150px;
}
.unit_header {
  position: relative;
  z-index: 1;
  background-size: 100% 100%;
  background-image: url($image_pre + 'Catergory_Selection_Header2x.png');
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 170px;
}
.unit_headertext {
  padding: 0 10px 8px 10px;
  text-align: center;
  display: flex;
  align-items: center;
  // color: rgba(92, 200, 239, 1.0);
  color: rgba(240, 240, 255, 1);
  // color: rgba(247, 149, 28, 1);
  filter: drop-shadow(1.5px 1.5px 0px black);
  @media (max-width: 768px) {
    filter: drop-shadow(1px 1px 0px black);
  }
}
.unit_contents {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.unit_centercontents {
  // background-color: salmon;
}
.unit_image {
  position: relative;
  background-size: 100% 100%;
  width: 115px;
  height: 115px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.unit_playbutton {
  position: absolute;
  top: 75%;
  background-image: url($image_pre + 'Category_Selection_Play_Button_Up2x.png');
  background-size: 100% 100%;
  width: 95px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(-2px 4px 0px rgba(0,0,0,0.5));
}
.unit_playbutton:hover {
  filter: drop-shadow(-2px 4px 0px rgba(0, 0, 0, 0));
  top: 77%;
  animation-name: jiggle;
  animation-delay: 0s;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-direction: normal;
}
.unit_playbuttontext {
  font-size: 1.3em;
  letter-spacing: 0.08em;
  color: rgba(254,242,4,1.0);
  background-size: 100% 100%;
  filter: drop-shadow(1px 0px 0px rgba(0, 0, 0, 0.5));
}
.unit_stars {
  background-size: 100% 100%;
  width: 90px;
  height: 25px;
  margin-top: 15px;
}
.unit_stars_0 {
  @extend .unit_stars;
  background-image: url($image_pre + 'Category_Selection_Base_Stars2x.png');
}
.unit_stars_1 {
  @extend .unit_stars;
  background-image: url($image_pre + 'Category_Selection_1_Stars2x.png');
}
.unit_stars_2 {
  @extend .unit_stars;
  background-image: url($image_pre + 'Category_Selection_2_Stars2x.png');
}
.unit_stars_3 {
  @extend .unit_stars;
  background-image: url($image_pre + 'Category_Selection_3_Stars2x.png');
}

// Animations

@keyframes slide-down {
  0% {
    transform: translateY(-100vh);
  } 100% {
    transform: translateY(0vh);
  }
}
@keyframes slide-right {
  0% {
    transform: translateX(-100vw);
  } 100% {
    transform: translateX(0vw);
  }
}
@keyframes jiggle {
  0% {
    transform: rotateZ(deg);
  } 75% {
    transform: rotateZ(0deg);
  } 80% {
    transform: rotateZ(15deg);
  } 84% {
    transform: rotateZ(-15deg);
  } 88% {
    transform: rotateZ(15deg);
  } 92% {
    transform: rotateZ(-15deg);
  } 96% {
    transform: rotateZ(15deg);
  } 100% {
    transform: rotateZ(-15deg);
  }
}