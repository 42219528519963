.wordless_vocabcontainer {
  border-radius: 10px;
  background-color: rgba(49,181,255,1);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border: 3px black solid;
}
.wordless_vocab {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
$keywordboxdim: clamp(60px, 10vmin, 120px);
.wordless_keywordbox {
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  border-radius: $keywordboxdim;
  width: $keywordboxdim;
  height: $keywordboxdim;
  position: absolute;
  top: clamp(23px, calc(5vh + 3px), 50px);
  left: calc((50% - ($keywordboxdim / 2)) - 1.8%);
  background:
    linear-gradient(
      145deg,
      rgba(170,240,255,1) 40%,
      rgba(49,181,255,1) 65%,
    );
  border: rgba(75, 81, 77, 1) 1vmin solid;
  padding: 1vmin;
}
$keywordboxdim_hard: clamp(60px, 8vmin, 90px);
.wordless_keywordbox_hard {
  @extend .wordless_keywordbox;
  width: $keywordboxdim_hard;
  height: $keywordboxdim_hard;
  left: calc((50% - ($keywordboxdim_hard / 2)) - 1.8%);
}
$keywordboxdim: clamp(50px, 8.33vmin, 100px);
.wordless_innerimg {
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  left: 5px;
  width: $keywordboxdim;
  height: $keywordboxdim;
}
.wordless_fullscreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}










.shakeButton:hover {
  animation-duration: .4s;
  animation-name: shake;
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  } 12.5% {
    transform: rotate(-30deg);
  } 25% {
    transform: rotate(0deg);
  } 37.5% {
    transform: rotate(30deg);
  } 50% {
    transform: rotate(0deg);
  } 62.5% {
    transform: rotate(-30deg);
  } 75% {
    transform: rotate(0deg);
  } 87.5% {
    transform: rotate(30deg);
  } 100% {
    transform: rotate(0deg);
  }
}