.popUpWord-Container {
  position: absolute;
  z-index: 9999999999999;
  top: -5%;
  right: -17%;
  padding: 0.8vw 1.7vw;
  transform: rotate(30deg);
  background-color: rgba(49, 181, 255, 1);
  border-radius: 50%;
  pointer-events: none;
}
.popupWord-shakeBox {
  animation-duration: .6s;
  animation-name: shake;
}
.popupWord-keyword {
  font-weight: bold;
  text-shadow: 2px 2px 5px white;
  color: black;
  font-size: 2.5vw;
  line-height: 0;
}
@keyframes shake {
  0% {
    transform: rotate(30deg);
  } 12.5% {
    transform: rotate(0deg);
  } 25% {
    transform: rotate(-30deg);
  } 37.5% {
    transform: rotate(0deg);
  } 50% {
    transform: rotate(30deg);
  } 62.5% {
    transform: rotate(0deg);
  } 75% {
    transform: rotate(-30deg);
  } 87.5% {
    transform: rotate(0deg);
  } 100% {
    transform: rotate(30deg);
  }
}