@import '../../index.scss';

.topcontainer {
  width: 50%;
  max-width: 500px;
  min-width: 250px;
  background-color: rgba(49, 181, 255, 1);
  border-radius: 10px;
  box-shadow: 2px 2px 3px black;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 35px;
  color: white;
  font-weight: bold;
  font-family: Aboriginal Sans;
  padding: 10px;
  text-shadow: 1px 1px 2px black;
}
.counting_centercontainer {
  position: absolute;
  top: 45px;
}
.counting_topcontainertext {
  line-height: 1em;
  font-size: max(min(2vw, 25px), 18px);
  text-align: center;
}
.counting_checkbutton {
  height: 60px;
  max-width: 200px;
  width: 40%;
  min-width: 100px;
  background-color: green;
  border-radius: 10px;
  box-shadow: 2px 2px 3px black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  color: white;
  font-weight: bold;
  font-family: Aboriginal Sans;
  margin-left: 10px;
}

.counting_fullscreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}


.counting_notclicked {
  position: absolute;
  background-color: rgba(202, 176, 133, 1);
  border-radius: 10px;
  border: 3px solid black;display: flex;
  justify-content: center;
  align-items: center;
}
.counting_clicked {
  @extend .counting_notclicked;
  background-color: rgba(150, 200, 255, 1);
}
.counting_item {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
