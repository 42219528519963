@import '../../../index.scss';
$button_dim: clamp(60px, 15vmin, 150px);

.difficultybadge_container {
  transform: translateY(40vh);
  @extend .hovercursor;
  position: relative;
  animation-name: slide_up;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}
.difficultybadge_level_all {
  position: relative;
  height: $button_dim;
  width: $button_dim;
  background-size: 100% 100%;
  align-self: flex-end;
}
.difficultybadge_level_1, .difficultybadge_level_5 {
  bottom: clamp(calc(55px - $button_dim/1.75), 5vmin, 50px);
  @extend .difficultybadge_level_all;
}
.difficultybadge_level_2, .difficultybadge_level_4 {
  bottom: clamp(calc(65px - $button_dim/1.75), 8vmin, 65px);
  @extend .difficultybadge_level_all;
}
.difficultybadge_level_3 {
  bottom: clamp(calc(70px - $button_dim/1.75), 9vmin, 75px);
  @extend .difficultybadge_level_all;
}

/* Badge Covers */
.difficultybadge_cover {
  background-image: url($image_pre + 'Level_Button_Cover2x.png');
  background-size: 100% 100%;
  height: calc($button_dim * 1.075);
  width: calc($button_dim * 1.075);
  display: flex;
  justify-content: center;
  align-items: center;
}
.difficultybadge_cover_unlocked {
  @extend .difficultybadge_cover;
  &:hover {
    animation-name: fade_out;
    animation-duration: 0.35s;
    animation-fill-mode: forwards;
  }
}
.difficultybadge_lock {
  background-image: url($image_pre + 'Level_Lock2x.png');
  background-size: 100% 100%;
  height: clamp(25px, 8vmin, 75px);
  width: clamp(25px, 8vmin, 75px);
}
.difficultybadge_scorecontainer {
  opacity: 0;
  position: absolute;
  bottom: 105%;
  left: 70%;
  animation-name: fade_in;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  @media (max-width: 768px) {
    height: clamp(25px, 5vmin, 50px);
    width: clamp(25px, 5vmin, 50px);
    left: 40%;
    bottom: 90%;
  }
}
.difficultybadge_star {
  background-image: url($image_pre + 'Level_Star2x.png');
  position: absolute;
  background-size: 100% 100%;
  height: clamp(20px, 5vmin, 40px);
  width: clamp(20px, 5vmin, 40px);
}
.difficultybadge_score {
  background-image: url($image_pre + 'Level_Score_Plate_Display2x.png');
  position: absolute;
  top: clamp(10px, 2vmin, 20px);
  left: clamp(5px, 2vmin, 10px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 100% 100%;
  width: clamp(40px, 8vmin, 80px);
  height: clamp(20px, 5vmin, 40px);
  font-size: clamp(8px, 3vmin, 22px);
}

/* Animations */
@keyframes fade_out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade_in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slide_up {
  0% {
    transform: translateY(40vh);
  }

  100% {
    transform: translateY(0);
  }
}