@import '../../index.scss';

.goodguess_keywordbox {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 5%;
  background-size: 100% 100%;
  margin-top: 15px;
  height: clamp(75px, 9.6vmin, 120px);
  width: clamp(262.5px, 33.6vmin, 420px);
  background-image: url($image_pre + 'Game_Type_Display2x.png');
}
.goodguess_keyword {
  border-radius: 5px;
  font-size: 4vmin;
  padding: 1vmin 2vmin 0vmin 2vmin;
  line-height: 1.4em;
}
.goodguess_vocabcontainer {
  position: absolute;
  border-radius: 10px;
  background-color: rgba(49, 181, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  border: black 3px solid;
  // width: calc(max(min(16.67vmin, 375px), 50px) + 15px);
  // height: calc(max(min(16.67vmin, 375px), 50px) + 15px);
}
.goodguess_vocab {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  // width: max(min(16.67vmin, 375px), 50px);
  // height: max(min(16.67vmin, 375px), 50px);
}
.goodguess_fullscreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.bottomstatbar_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.endlessBarLevel {
  display: flex;
  margin-right: 10px;
}