@import '../../index.scss';

.wti_fullscreen {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.wti_wordclick {
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border: solid 3px black;
  font-size: clamp(10px, 2.6vmin, 30px);
}

$imageDim: clamp(50px, 14vmin, 350px);
$imageDimHard: clamp(50px, 13vmin, 350px);

.wti_imageclick {
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border: solid 3px black;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 95% 95%;
  width: $imageDim;
  height: $imageDim;
}
.wti_imageclick_hard {
  @extend .wti_imageclick;
  width: $imageDimHard;
  height: $imageDimHard;
}