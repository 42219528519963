@import '../../index.scss';

.matching_startscreen {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
$width: clamp(262.5px, 33.6vmin, 420px);
.matching_keywordbox {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: calc(50% - calc($width / 2));
  background-size: 100% 100%;
  height: clamp(75px, 9.6vmin, 120px);
  width: clamp(262.5px, 33.6vmin, 420px);
  background-image: url($image_pre + 'Game_Type_Display2x.png');
}
.matching_keywordtext {
  border-radius: 5px;
  font-size: 5vmin;
  padding: 1vmin 2vmin 0vmin 2vmin;
  line-height: 1.4em;
}