.floatingnumber_plusminusimage {
  background-repeat: no-repeat;
  background-size: contain;
  animation-duration: 1500ms;
  animation-name: bounceInWAM;
  animation-iteration-count: 1;
  opacity: 0;
  pointer-events: none;
}
@keyframes bounceInWAM {
  0% {
    transform: scale(.2);
    opacity: 0;
  } 20% {
    opacity: 1;
  } 80% {
    opacity: 1;
  } 100% {
    transform: scale(.35) translate(0px, -400px);
    opacity: 0;
  }
}