@import '../../../index.scss';

.endendlessgame_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  color: rgba(40,40,40,1);
}

.endendlessgame_headercontainer {
  padding: 0px 0.272vmin;
  margin-top: 2vmin;
}
.endendlessgame_headercontainer h1 {
  font-size: clamp(13px, 2.7vmin, 40px);
  text-align: center;
  line-height: clamp(18px, 2.9vmin, 45px);
  padding: 0px 0.272vmin;
}
.endendlessgame_buttoncontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.endendlessgame_button {
  width: clamp(70px, 35vmin, 350px);
  height: clamp(16px, 8vmin, 80px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5vmin 0px;
  background-size: 100% 100%;
}
.endendlessgame_button_red {
  @extend .endendlessgame_button;
  background-image: url($image_pre + 'modal_red_button.png');
}
.endendlessgame_button_green {
  @extend .endendlessgame_button;
  background-image: url($image_pre + 'modal_green_button.png');
}
.endendlessgame_buttontext {
  color: white;
  filter: drop-shadow(1px 1px 0 black);
  letter-spacing: clamp(1px, 0.5vmin, 5px);
  font-size: clamp(1rem, 3.7vmin, 2.5rem);
}