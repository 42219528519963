@import '../../index.scss';

.bubblepop_itemcontainer {
  position: absolute;
  z-index: 99999;
  top: -30vh;
  height: 15vh;
  width: 15vh;
  border-radius: 15vh;
  border: solid 3px black;
}
.fallinganimation_easy {
  animation-name: Fall;
  animation-duration: 13s;
  animation-timing-function: linear;
}
.fallinganimation_medium {
  @extend .fallinganimation_easy;
  animation-duration: 11.5s;
}
.fallinganimation_hard {
  @extend .fallinganimation_easy;
  animation-duration: 10s;
}
@keyframes Fall {
  0% {
    top: -30vh;
    transform: translateX(0px);
  } 16.6% {
    transform: translateX(10vw);
  } 33.3% {
    transform: translateX(-15vw);
  } 50% {
    transform: translateX(20vw);
  } 66.6% {
    transform: translateX(-25vw);
  } 83.3% {
    transform: translateX(30vw);
  } 100% {
    top: 100vh;
    transform: translateX(-40vw);
  }
}
.bubblepop_hitbox {
  width: 19.5vh;
  height: 19.5vh;
  border-radius: 19.5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -2.55vh;
  left: -2.55vh;
}
.bubblepop_hitboxcontents {
  height: 9vh;
  width: 9vh;
  background-size: contain;
  background-repeat: no-repeat;
}

.bubblepop_keywordbox {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 5%;
  background-size: 100% 100%;
  margin-top: 15px;
  height: clamp(75px, 9.6vmin, 120px);
  width: clamp(262.5px, 33.6vmin, 420px);
  background-image: url($image_pre + 'Game_Type_Display2x.png');
}

.bubblepop_keyword {
  font-size: 5vmin;
  padding: 0vmin 2vmin 0vmin 2vmin;
  letter-spacing: 3px;
  color: white;
  filter: drop-shadow(3px 3px 2px black)
}
.bubblepop_outercontainer {
  // position: fixed;
  // top: 0px;
  // left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  overscroll-behavior: contain;
  overflow: hidden;
  overflow-y: hidden !important;
}
.bubblepop_gamecontainer {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  background-color: red;
}