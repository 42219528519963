.center {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.centerContentContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.all_pages_container {
  padding-top: 100px;
  padding-bottom: 95px;
}
.disabled_link {
  pointer-events: none;
}
.canvas_container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plusminuscontainer {
  position: absolute;
  z-index: 999999;
  height: 2;
  width: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.keyword {
  // font-family: "Aboriginal Sans";
  font-weight: bold;
  text-shadow: 2px 2px 5px white;
  color: black;
}

.keyword.flipped {
  animation-name: flipWord;
  animation-duration: 1s;
}

@keyframes flipWord {
  0% {
    transform:
      rotateY(0deg) scale(1)
  }

  50% {
    transform:
      rotateY(180deg) scale(1.4)
  }

  100% {
    transform:
      rotateY(360deg) scale(1)
  }
}